<script>

import appConfig from "@/app.config";
import progressBar from "../../../components/progressBar.vue";
import { lineChartData } from "./chartData";
import chart from "./chart.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      valid_form: true,
      selected_process: {},
      data: {},
      lineChartData,
      tot_pages: 0,
      surveyMember: {},
      processes: [],
      areas: [],
      check_box_id: null,
      radio_id: null,
      SearchInterviewersMode: false,
      searchInterviewersText: "",
      SearchAreasMode: false,
      searchAreasText: "",
      survey_chart_data: null,
    };
  },
  computed: {
    showProcesses() {
      return this.processes;
    },
    showAreas() {
      return this.areas;
    },
  },
  methods: {
    showPersonalTab() {
      document.querySelector(`#process_tab`)?.classList?.remove("active");
      document.querySelector(`#process_tab`)?.classList?.remove("show");
      document.querySelector(`#personal_tab`)?.classList?.add("active");
      document.querySelector(`#personal_tab`)?.classList?.add("show");
      document
        .querySelector('[data-bs-target="#process_tab"]')
        ?.classList?.remove("active");
      document
        .querySelector('[data-bs-target="process_tab"]')
        ?.setAttribute("aria-selected", false);
      document
        .querySelector('[data-bs-target="#personal_tab"]')
        ?.classList?.add("active");
      document
        .querySelector('[data-bs-target="personal_tab"]')
        ?.setAttribute("aria-selected", true);
    },
    showProcessTab() {
      document.querySelector(`#personal_tab`)?.classList?.remove("show");
      document.querySelector(`#personal_tab`)?.classList?.remove("active");
      document.querySelector(`#process_tab`)?.classList?.add("active");
      document.querySelector(`#process_tab`)?.classList?.add("show");
      document
        .querySelector('[data-bs-target="#personal_tab"]')
        ?.classList?.remove("active");
      document
        .querySelector('[data-bs-target="personal_tab"]')
        ?.setAttribute("aria-selected", false);
      document
        .querySelector('[data-bs-target="#process_tab"]')
        ?.classList?.add("active");
      document
        .querySelector('[data-bs-target="process_tab"]')
        ?.setAttribute("aria-selected", true);
    },
    removeErrorClass() {
      const name = document.querySelector("#personal_name");
      if (name?.value) name?.classList?.remove("error");
      const tel = document.querySelector("#personal_tel");
      if (tel?.value) tel?.classList?.remove("error");
      const area = document.querySelector("#personal_area");
      if (tel?.value) area?.classList?.remove("error");
      this.data?.questions?.forEach((question) => {
        const q_box = document.querySelector(`#question_box_${question?.id}`);
        if (question?.answer == null || question?.answer == "") {
          q_box?.classList?.remove("error_border_b");
        }
      });
    },
    validateForm() {
      this.valid_form = true;
      this.removeErrorClass();
      this.showPersonalTab();
      if (!this.data?.name) {
        this.valid_form = false;
        document.querySelector("#personal_name")?.classList?.add("error");
      }
      if (!this.data?.tel) {
        this.valid_form = false;
        document.querySelector("#personal_tel")?.classList?.add("error");
      }
      if (!this.data?.marketing_area_id) {
        this.valid_form = false;
        document.querySelector("#personal_area")?.classList?.add("error");
      }
      if (!this.valid_form) return false;
      this.showProcessTab();
      for (let i = 0; i < this.data?.questions?.length; i++) {
        console.log({ answer: this.data?.questions?.[i]?.answer });
        if (
          this.data?.questions?.[i]?.answer === null ||
          this.data?.questions?.[i]?.answer === undefined ||
          this.data?.questions?.[i]?.answer === ""
        ) {
          this.valid_form = false;
          document
            .querySelector(`#question_box_${this.data?.questions?.[i]?.id}`)
            .classList?.add("error_border_b");
        }
      }
      if (!this.valid_form) return false;
      this.valid_form = true;
      this.showPersonalTab();
      return true;
    },
    setData() {
      this.data.questions =
        this.surveyMember?.marketing_survey?.marketing_survey_questions;
      this.removeErrorClass();
    },
    async getQuestionTypes() {
      const res = await this.http.get("marketing-survey-question-types", null, null, true);
      this.check_box_id = res?.data?.filter(
        (i) => i?.name == "check_box"
      )?.[0]?.id;
      this.radio_id = res?.data?.filter((i) => i?.name == "radio")?.[0]?.id;
      console.log({
        check_box_id: this.check_box_id,
        radio_id: this.radio_id,
      });
    },
    async getSurveyMember() {
      const res = await this.http.get(
        "marketing-survey-team-members/" + this.$route.params?.id, null, null, true
      );
      this.surveyMember = res.data;
      this.processes = this.surveyMember?.marketing_survey_processes;
      this.areas = this.surveyMember?.marketing_survey_team_member_areas;
      console.log({ survey: this.surveyMember });

      const survey_chart_res = await this.http.post(
        "marketing-survey-processes/survey-interviews-count",
        {
          marketing_survey_id: this.surveyMember?.marketing_survey?.id,
        }, null, true
      );
      this.survey_chart_data = survey_chart_res?.data;
    },
    async addInterview() {
      this.data.marketing_survey_id = this.surveyMember?.marketing_survey?.id;
      this.data.marketing_survey_team_member_id = this.$route?.params?.id;
      console.log({ data: this.data });
      const isValid = this.validateForm();
      if (!isValid)
        return this.popup.alert({
          title: "Validation error",
          msg: "All Fields are required",
        });
      this.data?.questions?.forEach((q) => {
        if (
          q?.marketing_survey_question_type_id == this.check_box_id &&
          !q?.answer
        ) {
          q.answer = "false";
          console.log({ falsy_answer: q?.answer });
        } else if (
          q?.marketing_survey_question_type_id == this.check_box_id &&
          q?.answer
        ) {
          q.answer = "true";
          console.log({ truthy_answer: q?.answer });
        }
      });
      await this.http.post("marketing-survey-processes", this.data, null, true);
      setTimeout(() => {
        document.querySelector(".btn-close-add")?.click();
        this.getSurveyMember();
        this.data = {};
      }, 200);
    },
    navigate(user) {
      this.$router.push(`/${user?.id}`);
    },
    searchInterviewers() {
      this.SearchInterviewersMode = true;
      if (this.searchInterviewersText == "") {
        this.processes = this.surveyMember?.marketing_survey_processes;
      } else {
        this.processes =
          this.surveyMember?.marketing_survey_processes?.filter((i) =>
            i?.name
              ?.toLowerCase()
              ?.includes(this.searchInterviewersText?.toLowerCase())
          );
        console.log({ processes: this.processes });
      }
    },
    cancelSearchInterviewersMode() {
      this.searchInterviewersText = "";
      this.SearchInterviewersMode = false;
      this.processes = this.surveyMember?.marketing_survey_processes;
    },
    searchAreas() {
      console.log({ searchAreasText: this.searchAreasText });
      this.SearchAreasMode = true;
      if (this.searchAreasText === "") {
        this.areas = this.surveyMember?.marketing_survey_team_member_areas;
      } else {
        this.areas =
          this.surveyMember?.marketing_survey_team_member_areas?.filter((i) =>
            i?.marketing_area?.name
              ?.toLowerCase()
              ?.includes(this.searchAreasText?.toLowerCase())
          );
        console.log({ areas: this.areas });
      }
    },
    cancelSearchAreasMode() {
      this.searchAreasText = "";
      this.SearchAreasMode = false;
      this.areas = this.surveyMember?.marketing_survey_team_member_areas;
    },
    setSelectedProcess(process) {
      console.log('process :', process);
      this.selected_process = process;
    },
  },
  async created() {
    await this.getQuestionTypes();
    await this.getSurveyMember();
  },
  components: {

    progressBar,
    chart,
  },
};
</script>

<template>
  <!-- heading -->
  <div class="d-flex align-items-center justify-content-between mb-4">
    <div class="d-flex align-items-center">
      <router-link :to="{ name: 'team_member_surveys' }">
        <h4 class="m-0">
          {{ $t("menu.menuitems.surveys.subItems.team_member_surveys") }}
        </h4>
      </router-link>
      <div class="mx-2">\</div>
      <div>{{ surveyMember?.marketing_survey?.name }}</div>
    </div>
  </div>
  <div class="row">
    <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
      nav-class="justify-content-start nav-tabs-custom">
      <!-- interviewers -->
      <b-tab :title="$t('marketing.team_member.tabs.interviews')" active>
        <div class="row mb-4">
          <div class="col-4">
            <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px">
              <div class="align-items-center d-flex position-relative mx-3">
                <input @keyup="searchInterviewers()" type="text" class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')"
                  v-model="searchInterviewersText" style="background-color: #2a3042 !important" />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <!-- <button
                    @click="cancelSearchInterviewersMode()"
                    v-if="SearchInterviewersMode"
                    class="btn-close text-light"
                    style="position: absolute; top: 12px; left: 250px"
                  ></button> -->
              </div>
            </div>
          </div>

          <div class="col-8">
            <button type="button" class="btn btn-light float-end" data-bs-toggle="modal"
              data-bs-target="#addInterviewerModal" @click="setData">
              <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
            </button>
          </div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("users.userstable.id") }}</th>
              <th scope="col">
                {{ $t("marketing.team_member.interviewers.table.name") }}
              </th>
              <th scope="col">
                {{ $t("marketing.team_member.interviewers.table.tel") }}
              </th>
              <th scope="col">
                {{ $t("marketing.team_member.interviewers.table.area") }}
              </th>
              <th scope="col">{{ $t("users.userstable.created") }}</th>
              <th scope="col">
                {{ $t("marketing.survey_list.table.details") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="(user, index) in showProcesses" :key="user">
              <td>{{ index + 1 }}</td>
              <td>{{ user?.name }}</td>
              <td>{{ user?.tel }}</td>
              <td>{{ user?.marketing_area?.name }}</td>
              <td>{{ user?.created?.split("T")[0] }}</td>
              <td>
                <button @click="setSelectedProcess(user)" class="btn btn-primary btn-sm me-2" data-bs-toggle="modal"
                  data-bs-target="#InterviewDetailsModal">
                  <i class="bx bx-detail"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <!-- areas -->
      <b-tab :title="$t('marketing.team_member.tabs.areas')">
        <div class="row mb-4">
          <div class="col-4">
            <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px">
              <div class="align-items-center d-flex position-relative mx-3">
                <input @keyup="searchAreas()" type="text" class="form-control fa-lg text-light"
                  :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')"
                  v-model="searchAreasText" style="background-color: #2a3042 !important" />
                <i class="bx bx-search-alt search-icon text-light"></i>
                <!-- <button
                    @click="cancelSearchAreasMode()"
                    v-if="SearchAreasMode"
                    class="btn-close text-light"
                    style="position: absolute; top: 12px; left: 250px"
                  ></button> -->
              </div>
            </div>
          </div>

          <div class="col-8"></div>
        </div>
        <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
          <thead>
            <tr class="text-light text-center" style="background-color: #2a3042 !important">
              <th scope="col">{{ $t("users.userstable.id") }}</th>
              <th scope="col">
                {{ $t("marketing.team_members.table.name") }}
              </th>
              <th scope="col">
                {{ $t("marketing.team_members.table.target") }}
              </th>
              <th scope="col">{{ $t("users.userstable.created") }}</th>
              <th scope="col">
                {{ $t("marketing.team_member.tabs.progress") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" v-for="(area, index) in showAreas" :key="area">
              <td style="padding-top: 1.5rem">{{ index + 1 }}</td>
              <td style="padding-top: 1.5rem">
                {{ area?.marketing_area?.name }}
              </td>
              <td style="padding-top: 1.5rem">{{ area?.target }}</td>
              <td style="padding-top: 1.5rem">
                {{ area?.created?.split("T")[0] }}
              </td>
              <td class="d-flex justify-content-center align-items-center">
                <progressBar v-if="area?.marketing_area?.marketing_survey_processes" :max="(area?.marketing_area?.marketing_survey_processes
        ?.length /
        area?.target) *
        100
        " :id="area?.id" />
              </td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <!-- progress -->
      <b-tab :title="$t('marketing.team_member.tabs.progress')">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">
                  {{ $t("marketing.team_member.line_chart_title") }}
                </h4>
                <chart v-if="survey_chart_data" :type="'line'" :categories="survey_chart_data?.categories"
                  :series="survey_chart_data?.series" :title="'Interviews count this week'" x_title="Days"
                  y_title="Interviews" series_title="Interviews" :yaxis_max="survey_chart_data?.yaxis_max" />
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
  <!-- end  table -->

  <!--Start Add Modal-->
  <div class="modal fade" id="addInterviewerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addInterview()">
          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.add") }}
            </h5>
            <button type="button" class="btn-close-add" data-bs-dismiss="modal" aria-label="Close" style="margin: 0">
              X
            </button>
          </div>

          <div class="modal-body">
            <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
              nav-class="justify-content-start nav-tabs-custom">
              <!-- personal tab -->
              <b-tab id="personal_tab" :title="$t('marketing.team_member.interviewers.tabs.personal')
        " active>
                <label for="name">{{ `${$t("popups.name")}` }}</label>
                <input v-model="data.name" id="personal_name" class="form-control mb-2" type="text" />
                <label for="tel">{{ `${$t("popups.tel")}` }}</label>
                <input v-model="data.tel" id="personal_tel" class="form-control mb-2" type="tel" />
                <label for="personal_area">{{
        `${$t("marketing.marketing_area")}`
      }}</label>
                <select id="personal_area" class="form-select" v-model="data.marketing_area_id">
                  <option v-for="item in surveyMember?.marketing_survey_team_member_areas" :key="item?.id"
                    :value="item?.marketing_area?.id">
                    {{ item?.marketing_area?.name }}
                  </option>
                </select>
              </b-tab>
              <!-- process tab -->
              <b-tab id="process_tab" :title="$t('marketing.team_member.interviewers.tabs.process')">
                <div v-for="question in data?.questions" :key="question?.id" class="mb-2 py-2 question_box"
                  :id="`question_box_${question?.id}`">
                  <p>{{ question?.question }}</p>
                  <!-- checkbox -->
                  <div v-if="+question?.marketing_survey_question_type_id == check_box_id" class="d-flex"
                    style="flex-direction: column">
                    <label class="d-flex align-items-center my-2" style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" name="yesNo" class="input"
                        v-model="question.answer" :value="true" />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box check"></span>
                      {{ $t("popups.yes") }}
                    </label>
                    <label class="d-flex align-items-center my-2" style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" name="yesNo" class="input"
                        v-model="question.answer" :value="false" />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box cross"></span>
                      {{ $t("popups.no") }}
                    </label>
                  </div>
                  <!-- radio -->
                  <div v-if="+question?.marketing_survey_question_type_id == radio_id" class="d-flex"
                    style="flex-direction: column">
                    <label v-for="option in question?.marketing_survey_question_options" :key="option?.id"
                      class="d-flex align-items-center my-2" style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" :name="question?.question"
                        v-model="question.answer" :value="option?.id" class="input" />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box radio"></span>
                      {{ option?.option }}
                    </label>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="modal-footer px-4 pb-4" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!--Review Details Modal-->
  <div class="modal fade" id="InterviewDetailsModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form>
          <div class="modal-header pt-4 px-4">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ selected_process?.name }}
            </h5>
            <button type="button" class="btn-close-details" data-bs-dismiss="modal" aria-label="Close"
              style="margin: 0">
              X
            </button>
          </div>

          <div class="modal-body">
            <b-tabs content-class="p-4" class="pt-2" nav-wrapper-class="nav-item"
              nav-class="justify-content-start nav-tabs-custom">
              <!-- personal tab -->
              <b-tab id="personal_tab" :title="$t('marketing.team_member.interviewers.tabs.personal')
        " active>
                <label for="name_details">{{ `${$t("popups.name")}` }}</label>
                <input v-model="selected_process.name" disabled id="name_details" class="form-control mb-2"
                  type="text" />
                <label for="tel_details">{{ `${$t("popups.tel")}` }}</label>
                <input v-model="selected_process.tel" disabled id="tel_details" class="form-control mb-2"
                  type="number" />
                <label for="tel_details">{{
        `${$t("marketing.marketing_area")}`
      }}</label>
                <select disabled id="personal_area" class="form-select" v-model="selected_process.marketing_area_id">
                  <option :value="selected_process?.marketing_area?.id">
                    {{ selected_process?.marketing_area?.name }}
                  </option>
                </select>
              </b-tab>
              <!-- process tab -->
              <b-tab id="process_tab" :title="$t('marketing.team_member.interviewers.tabs.process')">
                <div v-for="answer in selected_process?.marketing_survey_processes_answers" :key="answer?.id"
                  class="mb-2 py-2" style="border-bottom: 1px solid #ddd">
                  <label for="name" class="mb-1">{{
        answer?.marketing_survey_question?.question
      }}</label>
                  <!-- checkbox -->
                  <div v-if="+answer?.marketing_survey_question?.marketing_survey_question_type_id == check_box_id"
                    class="d-flex" style="flex-direction: column">
                    <label class="d-flex align-items-center my-2" style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" name="yesNo" class="input"
                        :checked="answer?.answer == 'true'" disabled />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box check"></span>
                      {{ $t("popups.yes") }}
                    </label>
                    <label class="d-flex align-items-center my-2" style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" name="yesNo" class="input"
                        :checked="answer?.answer == 'false'" disabled />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box cross"></span>
                      {{ $t("popups.no") }}
                    </label>
                  </div>
                  <!-- radio -->
                  <div v-if="+answer?.marketing_survey_question?.marketing_survey_question_type_id == radio_id"
                    class="d-flex" style="flex-direction: column">
                    <label v-for="option in answer?.marketing_survey_question?.marketing_survey_question_options"
                      :key="option?.id" class="d-flex align-items-center my-2"
                      style="cursor: pointer; position: relative">
                      <input style="display: none; appearance: none" type="radio" :name="option?.question" disabled
                        :checked="+answer?.answer == option?.id" class="input" />
                      <span style="
                            position: relative;
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: #fff;
                          " class="box radio"></span>
                      {{ option?.option }}
                    </label>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Review Details Modal-->

  <!--   Apps  Pagination     -->
  <ul v-if="tot_pages >= 20" class="pagination pagination-rounded justify-content-center mb-2">
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == total_pages }">
      <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>

<style scoped>
.box {
  transition: 0.5s;
  margin-inline: 0.75rem;
}

.box::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #4a4747;
  box-shadow: 0 -18px 0 #4a4747;
  transition: 0.5s;
}

.input:checked~.box.check::before {
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.radio::before {
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.cross::before {
  transform: rotate(-45deg) translate(7px, -7px);
  background: #f00;
  box-shadow: 0 0 0 transparent;
}

.box::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: #4a4747;
  box-shadow: 18px 0 0 #4a4747;
  transition: 0.5s;
}

.input:checked~.box.check::after {
  height: 50%;
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.check {
  transform: rotate(-45deg) translate(7px, -7px);
}

.input:checked~.box.radio::after {
  height: 50%;
  background: #0f0;
  box-shadow: 0 0 0 transparent;
}

.input:checked~.box.radio {
  transform: rotate(-45deg) translate(7px, -7px);
}

.input:checked~.box.cross::after {
  transform: rotate(-45deg) translate(7px, 6px);
  background: #f00;
  box-shadow: 0 0 0 transparent;
}

.question_box {
  border-bottom: 1px solid #ddd;
}

.error {
  border: 2px solid rgb(245, 44, 44);
}

.error_border_b {
  border-bottom: 2px solid rgb(245, 44, 44) !important;
}
</style>
