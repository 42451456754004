<script>
  export default {
    props: ["max", "id"],
    data() {
      return {
        sim: null,
        currentValue: 0,
      };
    },
    computed: {
      current() {
        return this.currentValue;
      },
    },
    methods: {
      progressSim() {
        //نعرف الكانفاس كمتغير كالتالي
        var ctx = document
          .getElementById(`my_canvas_${this.id}`)
          .getContext("2d");
        // هذا المتغير هو زاوية البداية
        var start = 0;
        // هذا المتغير هو عرض الكانفاس
        var cw = ctx.canvas.width;
        // هذا المتغير هو طول الكانفاس
        var ch = ctx.canvas.height;
        // هذا المتغير سنستخدمه للتحويل من راديان الى نسبة مئوية لأن الدوائر والاقواس في الكانفاس تحسب بالراديان
        var diff;
        // الان نحسب النسبة المئوية من الراديان باتقسيم الرقم على مئة وضربه في طول قوس الدائرة الكاملة
        diff = (this.current / 100) * Math.PI * 2;
        // لتحديد نقطة بداية الكانفاس على المحور اكس والمحور واي وطول وعرض الكانفاس
        ctx.clearRect(0, 0, cw, ch);
        // سماكة خط الرسم
        ctx.lineWidth = 4;
        // لون نص الكتابة التي تظهر داخل الكانفاس
        ctx.fillStyle = "#3f51b5";
        // لون خط الرسم الذي سنرسم به البار الدائري
        ctx.strokeStyle = "#3f51b5";
        // محاذاة النص للمنتصف
        ctx.textAlign = "center";
        // لتحديد حجم ونوع الخط
        ctx.font = "10px Arial";
        // هذا الامر للنص الذي نريده ان يظهر وهو مكون من اربع خانات محصورة بين قوسين
        // الخانات بالترتيب من اليسار الى اليمين الخانة الاولى النص المراد ان يظهر واضفنا اليه نسبة مئوية بين علامتين تنصيص
        // الثانية مكان النص بالنسبة لمحور اكس واخترناه هنا في منتصف المحور العرضي
        // الثالثة مكان النص بالنسبة لمحور واي واخترناه هنا في منتصف المحور الطولي
        // الرابعة اكبر عرض للنص
        ctx.fillText(this.current + "%", cw * 0.5, ch * 0.5 + 2, cw);
        // هذا الامر لبدء الرسم
        ctx.beginPath();
        // هذا الامر لبداية رسم القوس او الدائرة وهو مقسم لست خانات محصورة بين قوسين وهي كالتالي بالترتيب من اليسار الى اليمين
        // الاولى لتحديد مركز الدائرة او القوس على محور اكس
        // الثانية لتحديد مركز الدائرة او القوس على محور واي
        // الثالثة نصف قطر الدائرة
        // الرابعة زاوية البداية
        // الخامسة زاوية النهاية وهنا اضفت لها المتغير الذي عرفناه سابقا للتغير تدريجيا
        // السادسة اتجه الدوران فالس مع عقارب الساعة وترو عكس عقارب الساعة
        ctx.arc(
          ctx.canvas.width / 2,
          ctx.canvas.height / 2,
          70 / 4,
          start,
          diff + start,
          false
        );
        // هذا الامر لتنفيذ الرسم
        ctx.stroke();
        // هنا نعطي قيمة تصاعدية للرقم داخل الكانفاس
        this.currentValue = this.currentValue + 1;
        // في حال وصل الى الرقم الذي نريد قم بعمل التالي ملاحظة تستطيع كتابة الرقم الذي تريد وتضيف عليه واحد
        if (this.current >= this.max + 1) {
          // اوقف الفنكشن التالية
          if (this.sim) clearTimeout(this.sim);
        }
      },
    },
    mounted() {
      console.log({ max: this.max });
      if (!this.max) return;
      // هذا المتغير لتشغيل الفنكشن كل مدة زمنية تستطيع تغييرها كما تريد وهنا اخترنا 50 جزء من الثانية
      this.sim = setInterval(this.progressSim, 50);
    },
  };
</script>

<template>
  <div class="container text-center">
    <canvas :id="`my_canvas_${id}`" width="50" height="50"></canvas>
  </div>
</template>
